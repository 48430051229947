import { frontendURL } from '../../../../helper/URLHelper';

const Index = () => import('./Index.vue');
const TemplateForm = () => import('./TemplateForm.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/whatsapp-templates'),
      children: [
        {
          path: 'list',
          name: 'whatsapp_templates_list',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
        {
          path: 'new',
          name: 'whatsapp_templates_new',
          meta: {
            permissions: ['administrator'],
          },
          props: route => {
            return { 
              templateName: route.query.templateName,
              category: route.query.category
             };
          },
          component: TemplateForm,
        },
        {
          path: ':template_id/edit',
          name: 'whatsapp_templates_edit',
          meta: {
            permissions: ['administrator'],
          },
          props: route => {
            return { templateId: route.params.template_id };
          },
          component: TemplateForm,
        },
      ],
    },
  ],
};
