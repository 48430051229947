import ApiClient from './ApiClient';

export class WhatsappTemplatesAPI extends ApiClient {
  constructor() {
    super('whatsapp_templates', { accountScoped: true });
  }

  syncTemplates() {
    return axios.post(`${this.url}/sync_templates`);
  }

  filterTemplates({ status, category, language, query } = {}) {
    return axios.get(this.url, {
      params: {
        by_status: status,
        by_category: category,
        by_language: language,
        search: query,
      },
    });
  }
}

export default new WhatsappTemplatesAPI();
