import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import WhatsappTemplatesAPI from '../../api/whatsappTemplates';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getTemplates(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getSortedTemplates(_state) {
    return (sortOrder, property) =>
      [..._state.records].sort((a, b) => {
        if (sortOrder === 'asc') {
          return a[property].localeCompare(b[property]);
        }
        return b[property].localeCompare(a[property]);
      });
  },
};

export const actions = {
  get: async function getTemplates({ commit }) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: true });
    try {
      const response = await WhatsappTemplatesAPI.get();
      commit(types.SET_TEMPLATES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },

  syncTemplates: async function syncTemplates({ commit, dispatch }) {
    try {
      await WhatsappTemplatesAPI.syncTemplates();
    } catch (error) {
      // Ignore error
    } finally {
      dispatch('get');
    }
  },

  getById: async function getTemplateById({ commit }, id) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await WhatsappTemplatesAPI.show(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetchingItem: false });
    }
  },

  create: async function createTemplates({ commit }, cannedObj) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: true });
    try {
      const response = await WhatsappTemplatesAPI.create(cannedObj);
      commit(types.ADD_TEMPLATE, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },

  update: async function updateTemplates({ commit }, { id, ...updateObj }) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isUpdating: true });
    try {
      const response = await WhatsappTemplatesAPI.update(id, updateObj);
      commit(types.EDIT_TEMPLATE, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async function deleteTemplates({ commit }, id) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: true });
    try {
      await WhatsappTemplatesAPI.delete(id);
      commit(types.DELETE_TEMPLATE, id);
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: false });
    }
  },

  filter: async function filterTemplates(
    { commit },
    { status = '', category = '', language = '', query = '' } = {}
  ) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: true });
    try {
      const response = await WhatsappTemplatesAPI.filterTemplates({
        status,
        category,
        language,
        query,
      });
      commit(types.SET_TEMPLATES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_TEMPLATE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_TEMPLATES]: MutationHelpers.set,
  [types.ADD_TEMPLATE]: MutationHelpers.create,
  [types.EDIT_TEMPLATE]: MutationHelpers.update,
  [types.DELETE_TEMPLATE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
