import store from 'dashboard/store';

export const getters = {
  getTeams($state) {
    return Object.values($state.records).sort((a, b) => a.id - b.id);
  },
  getMyTeams($state, $getters) {
    return $getters.getTeams.filter(team => {
      const { is_member: isMember } = team;
      return isMember;
    });
  },
  getTeamsForReportFilter($state) {
    const { getCurrentUserIsAdmin: userIsAdmin } = store.getters;

    let records = Object.values($state.records).sort((a, b) => a.id - b.id);
    if (userIsAdmin) return records;

    return records.filter(team => team.is_member);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getTeam: $state => id => {
    const team = $state.records[id];
    return team || {};
  },
};
